exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-health-and-wellness-blog-js": () => import("./../../../src/pages/health-and-wellness-blog.js" /* webpackChunkName: "component---src-pages-health-and-wellness-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-policies-js": () => import("./../../../src/pages/policies.js" /* webpackChunkName: "component---src-pages-policies-js" */),
  "component---src-pages-review-us-js": () => import("./../../../src/pages/review-us.js" /* webpackChunkName: "component---src-pages-review-us-js" */),
  "component---src-pages-sanity-blogs-slug-current-js": () => import("./../../../src/pages/{SanityBlogs.slug__current}.js" /* webpackChunkName: "component---src-pages-sanity-blogs-slug-current-js" */),
  "component---src-pages-sanity-services-slug-current-js": () => import("./../../../src/pages/{SanityServices.slug__current}.js" /* webpackChunkName: "component---src-pages-sanity-services-slug-current-js" */),
  "component---src-pages-social-saturdays-js": () => import("./../../../src/pages/social-saturdays.js" /* webpackChunkName: "component---src-pages-social-saturdays-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-wellness-experts-js": () => import("./../../../src/pages/wellness-experts.js" /* webpackChunkName: "component---src-pages-wellness-experts-js" */),
  "component---src-pages-wellness-packages-js": () => import("./../../../src/pages/wellness-packages.js" /* webpackChunkName: "component---src-pages-wellness-packages-js" */)
}

